import '../css/style.css';
import './form-validation';

document.addEventListener('DOMContentLoaded', function () {
    const smoothLinks = document.querySelectorAll('a[href^="#"]');

    const menu = document.querySelector('.header-menu');
    const burger = document.querySelector('.burger-menu');

    const showMobileMenu = () => {
        menu.classList.toggle('active');
        document.body.classList.toggle('no-scroll');
    };

    burger.addEventListener('click', showMobileMenu);

    new Swiper('.case-section__slider', {
        navigation: {
            nextEl: '.cases-slider-button-next',
            prevEl: '.cases-slider-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        direction: 'horizontal',
        slidesPerView: 1,
        loop: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1440: {
                slidesPerView: 1,
            },
        },
    });

    new Swiper('.partners-slider', {
        navigation: {
            nextEl: '.partners-slider-button-next',
            prevEl: '.partners-slider-button-prev',
        },
        pagination: {
            el: '.partners-slider-pagination',
        },
        direction: 'horizontal',
        slidesPerView: 6,
        spaceBetween: 28,
        loop: true,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 6,
            },
        },
    });

    new Swiper('.acknowledgments-slider', {
        navigation: {
            nextEl: '.acknowledgments-slider-button-next',
            prevEl: '.acknowledgments-slider-button-prev',
        },
        pagination: {
            el: '.acknowledgments-slider-pagination',
        },
        direction: 'horizontal',
        slidesPerView: 3.5,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            2160: {
                grid: {
                    rows: 2,
                },
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });

    new Swiper('.team-slider', {
        navigation: {
            nextEl: '.team-slider-button-next',
            prevEl: '.team-slider-button-prev',
        },
        pagination: {
            el: '.team-slider-pagination',
        },
        direction: 'horizontal',
        spaceBetween: 20,
        slidesPerView: 3.5,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1280: {
                slidesPerView: 3.5,
            },
            1440: {
                slidesPerView: 3.5,
            },
            2160: {
                grid: {
                    rows: 3,
                },
                slidesPerView: 2,
            },
        },
    });

    new Swiper('.services-slider', {
        navigation: {
            nextEl: '.services-slider-button-next',
            prevEl: '.services-slider-button-prev',
        },
        pagination: {
            el: '.services-slider-pagination',
        },
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 4,
        loop: true,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1440: {
                slidesPerView: 4,
            },
        },
    });

    const formInputs = document.querySelectorAll('.feedback-form__input');
    const formLabels = document.querySelectorAll('.feedback-form__label');

    if (!formInputs && !formLabels) return;

    formInputs.forEach((input) => {
        input.addEventListener('focus', () => {
            formLabels.forEach((label) => {
                if (label.getAttribute('for') === input.id) {
                    label.style.display = 'none';
                }
            });
        });

        input.addEventListener('blur', (event) => {
            if (event.target.value.length) return;
            formLabels.forEach((label) => {
                if (label.getAttribute('for') === input.id) {
                    label.style.display = 'flex';
                }
            });
        });
    });

    for (let smoothLink of smoothLinks) {
        smoothLink.addEventListener('click', function (e) {
            const hrefAttribute = smoothLink.getAttribute('href');
            if (hrefAttribute.startsWith('#')) {
                e.preventDefault();
                if (
                    window.location.pathname === '/' ||
                    window.location.pathname.endsWith('index.html')
                ) {
                    document.querySelector(hrefAttribute).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                } else {
                    window.location.href = '/index.html' + hrefAttribute;
                }
            }
        });
    }
});
